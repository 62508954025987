/**
 * Created by slava on 24.03.17.
 */

var SettingsWindow = CleverappsWindow.extend({
    onWindowLoaded: function () {
        var user = this.createUser();

        var buttons = [];

        if (!cleverapps.flags.nologin && !connector.social.isLoggedIn()) {
            buttons.push(this.addSaveProgressButton());
        }

        if (!cleverapps.flags.nologin && connector.social.isLoggedIn() && connector.platform.oneOf(connector.TEST)) {
            buttons.push(this.addLogoutButton());
        }

        if (cleverapps.config.languages.length > 1 && (cleverapps.config.type === "merge" || !cleverapps.environment.isGameScene())) {
            buttons.push(this.addLanguages());
        }

        if ((["merge", "blocks"].includes(cleverapps.config.type) || !Game.currentGame) && Chat.IsAvailable()) {
            buttons.push(this.addSupportButton());
        }

        if (cleverapps.crossPromo && cleverapps.crossPromo.havePromotion()) {
            buttons.push(this.addCrossPromoButton());
        }

        if (typeof Game !== "undefined" && Game.currentGame && Game.currentGame.outcome === undefined && cleverapps.environment.isGameScene() && ["match3", "tile3", "blocks"].indexOf(cleverapps.config.type) !== -1) {
            buttons.push(this.addGiveUpButton());
        }

        var styles = cleverapps.styles.SettingsWindow;
        var isVertical = cleverapps.resolution.isVertical();

        var buttonsLayout = this.buttonsLayout = new cleverapps.GridLayout(buttons, {
            columns: isVertical ? 1 : 2,
            margin: styles.grid.margin
        });

        var controlButtons = this.createControlButtonsLayout();

        var content = new cleverapps.Layout([user, buttonsLayout, controlButtons, this.createVersion()].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        this._super({
            title: "SettingsWindow.title",
            name: "settingswindow",
            content: content
        });

        this.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 3);
        this.adjustButtons();

        var cheapProductNode = new cc.Node();
        cheapProductNode.setContentSize(styles.cheapProduct);
        cheapProductNode.setPositionRound(styles.cheapProduct);
        content.addChild(cheapProductNode);

        cleverapps.UI.onClick(cheapProductNode, function () {
            console.log("cheap product click");
        }, {
            onDoubleClick: function () {
                var cheapProduct = Product.CreateById("cheapProduct");

                if (!cheapProduct) {
                    cleverapps.notification.create("СheapProduct not found, payments - " + connector.payments.getName());
                    return;
                }
                cheapProduct.buy(function (code) {
                    if (code === connector.CODE_SUCCEED) {
                        this.close();
                    }
                }.bind(this));
            }.bind(this)
        });
    },

    getPerson: function () {
        return cleverapps.styles.SettingsWindow.person;
    },

    createUser: function () {
        var styles = cleverapps.styles.SettingsWindow;

        var background = cleverapps.UI.createScale9Sprite(bundles.settings_window.frames.user_info_bg, cleverapps.UI.Scale9Rect.TwoPixelXY);
        background.setContentSize2(styles.user.size);

        var player = connector.player;

        var avatar = new cleverapps.UI.Avatar(player, {
            defaultImage: bundles.settings_window.frames.avatar_default,
            frame: bundles.settings_window.frames.avatar_frame,
            mask: new cc.Sprite(bundles.settings_window.frames.avatar_mask),
            styles: styles.avatar.padding
        });

        avatar.setPositionRound(styles.avatar.position);
        background.addChild(avatar);

        var playerName = player.name || ("Player_" + connector.platform.getUserID().slice(-3));
        var name = cleverapps.UI.generateOnlyText(playerName, cleverapps.styles.FONTS.SETTINGS_WINDOW_NAME_TEXT);
        name.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        name.fitTo(styles.name.size[cleverapps.resolution.mode].width);
        name.setPositionRound(styles.name.position);
        background.addChild(name);

        var idText = cleverapps.UI.generateOnlyText("ID: " + player.id, cleverapps.styles.FONTS.SETTINGS_WINDOW_ID_TEXT);
        idText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_LEFT);
        idText.fitTo(styles.id.size[cleverapps.resolution.mode].width);
        idText.setOpacity(128);

        var copyId;
        if (!cleverapps.flags.norest || cleverapps.config.debugMode) {
            copyId = new cc.Sprite(bundles.buttons_main.frames.copyid_icon);
        }

        var id = new cleverapps.Layout([idText, copyId].filter(Boolean), {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.id.margin
        });

        if (copyId) {
            var callback = function () {
                cleverapps.copyToClipboard(connector.platform.getUserID(), function () {
                    cleverapps.notification.create("SettingsWindow.UserID.copied");
                });
            };

            cleverapps.UI.onClick(id, callback, { interactiveScale: false });
            cleverapps.UI.onClick(copyId, callback, { hover: true });
        }

        id.setPositionRound(styles.id.position);
        background.addChild(id);

        if (connector.platform.listShareChannels().length > 0) {
            var invite = new InviteButton({
                width: styles.invite.width,
                height: styles.invite.height,
                type: cleverapps.styles.UI.Button.Images.small_button_green,
                content: new cc.Sprite(bundles.buttons_main.frames.invite_icon),
                onSuccess: this.closeWindow.bind(this)
            });

            invite.setPositionRound(styles.invite.position);
            background.addChild(invite);
        }

        return cleverapps.UI.wrapWithPadding(background, styles.user.padding);
    },

    addGiveUpButton: function () {
        var styles = cleverapps.styles.SettingsWindow;

        return new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_red,
            content: this.createTextWithIcon("GiveUp", bundles.buttons_main.frames.giveup_icon),
            onClicked: function () {
                this.close();

                cleverapps.focusManager.whenFreeFocus(function () {
                    var scene = cleverapps.scenes.getRunningScene();
                    scene.closeAction();
                });
            }.bind(this),
            width: styles.button.width,
            height: styles.button.height
        });
    },

    addSupportButton: function () {
        var styles = cleverapps.styles.SettingsWindow;

        return new cleverapps.UI.Button({
            content: this.createTextWithIcon("SupportButton", bundles.buttons_main.frames.support_icon),
            onClicked: function () {
                cleverapps.focusManager.distract({
                    focus: "ChatScene",
                    action: function (f) {
                        this.close();

                        cleverapps.scenes.replaceScene(new ChatScene(cleverapps.chat), f);
                    }.bind(this)
                });
            }.bind(this),
            width: styles.button.width,
            height: styles.button.height,
            mark: cleverapps.chat.hasUnread
        });
    },

    addLanguages: function () {
        var styles = cleverapps.styles.SettingsWindow;

        return new cleverapps.UI.Button({
            content: this.createTextWithIcon("Language", bundles.buttons_main.frames.language_icon),
            onClicked: function () {
                new LanguagesWindow();
                this.close();
            }.bind(this),
            width: styles.button.width,
            height: styles.button.height
        });
    },

    createLine: function () {
        var styles = cleverapps.styles.SettingsWindow;

        var line = cleverapps.UI.createScale9Sprite(bundles.settings_window.frames.line, cleverapps.UI.Scale9Rect.TwoPixelXY);
        line.setContentSize2(styles.line.size[cleverapps.resolution.mode]);

        return cleverapps.UI.wrapWithPadding(line, styles.line.padding[cleverapps.resolution.mode]);
    },

    createControlButtonsLayout: function () {
        var isVertical = cleverapps.resolution.isVertical();
        var styles = cleverapps.styles.SettingsWindow;

        var followUs = this.createFollowUs();
        var controlButtons = this.createControlButtons();

        if (!followUs && !controlButtons) {
            return;
        }

        var grid = new cleverapps.GridLayout([followUs, isVertical && this.createLine(), controlButtons].filter(Boolean), {
            columns: isVertical ? 1 : 2,
            margin: styles.grid.margin
        });

        var layout = new cleverapps.Layout([!isVertical && this.createLine(), grid].filter(Boolean), {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });

        return layout;
    },

    createControlButtons: function () {
        var data = [];

        if (connector.platform.oneOf(connector.TEST, connector.FACEBOOK, connector.ANDROID, connector.IOS, connector.WECHAT, connector.INSTANT, connector.DISCORD, connector.CRAZY)) {
            data.push({
                type: cleverapps.styles.UI.Button.Images.settings_control_button,
                content: new cc.Sprite(bundles.buttons_main.frames.info_icon),
                onClicked: function () {
                    new AboutWindow();
                }
            });
        }

        if (!connector.platform.hasAudioControls) {
            data = data.concat([
                {
                    type: cleverapps.styles.UI.Button.Images.settings_control_button,
                    content: new cc.Sprite(bundles.buttons_main.frames.sound_on_png),
                    contentOff: new cc.Sprite(bundles.buttons_main.frames.sound_off_png),
                    toggle: cleverapps.settings.toggleSound.bind(cleverapps.settings),
                    settings: "sound",
                    listener: "onChangeSoundListener"
                },
                {
                    type: cleverapps.styles.UI.Button.Images.settings_control_button,
                    content: new cc.Sprite(bundles.buttons_main.frames.music_on_png),
                    contentOff: new cc.Sprite(bundles.buttons_main.frames.music_off_png),
                    toggle: cleverapps.settings.toggleMusic.bind(cleverapps.settings),
                    settings: "music",
                    listener: "onChangeMusicListener"
                }
            ]);
        }

        if (SettingsWindow.IsExitButtonAvailable() && cleverapps.environment.isMainScene()) {
            data.push({
                type: cleverapps.styles.UI.Button.Images.settings_control_red_button,
                content: new cc.Sprite(bundles.buttons_main.frames.exit_icon),
                onClicked: function () {
                    cleverapps.focusManager.distract({
                        focus: "ConfirmExitWindow",
                        action: function (f) {
                            new ConfirmExitWindow({
                                action: function () {
                                    connector.platform.closeApplication();
                                }
                            });

                            cleverapps.focusManager.onceNoWindowsListener = f;
                        }
                    });

                    this.close();
                }.bind(this)
            });
        }

        if (data.length === 0) {
            return;
        }

        var styles = cleverapps.styles.SettingsWindow;

        var buttons = data.map(function (data) {
            var button = new cleverapps.UI.Button({
                type: data.type,
                content: data.content,
                contentOff: data.contentOff,
                width: styles.controlButton.width,
                height: styles.controlButton.height,
                onClicked: data.onClicked || data.toggle
            });

            if (data.settings) {
                button.setBright(cleverapps.settings[data.settings]);
                cleverapps.settings[data.listener] = button.createListener(function () {
                    button.setBright(cleverapps.settings[data.settings]);
                });
            }

            return button;
        });

        var layout = new cleverapps.Layout(buttons, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.controlButton.margin
        });

        return cleverapps.UI.wrapWithPadding(layout, {
            x: Math.max(0, (styles.button.width - layout.width) / 2)
        });
    },

    addSaveProgressButton: function () {
        var styles = cleverapps.styles.SettingsWindow;
        var sources = connector.social.listAvailable();

        if (connector.info.isNative || connector.platform.oneOf(connector.FACEBOOK) || sources.length > 1) {
            return new cleverapps.UI.Button({
                content: this.createTextWithIcon("SettingsWindow.SaveProgressButton", bundles.buttons_main.frames.save_progress_icon),
                onClicked: function () {
                    new SignInWindow();
                    this.close();
                }.bind(this),
                width: styles.button.width,
                height: styles.button.height
            });
        }

        var icon = LoginButton.getIcon(connector.info.source) || bundles.buttons_main.frames.save_progress_icon;

        return new LoginButton({
            width: styles.button.width,
            height: styles.button.height,
            content: this.createTextWithIcon("SettingsWindow.SaveProgressButton", icon),
            onSuccess: this.closeWindow.bind(this)
        });
    },

    addLogoutButton: function () {
        var styles = cleverapps.styles.SettingsWindow;

        return new cleverapps.UI.Button({
            width: styles.button.width,
            height: styles.button.height,
            content: this.createTextWithIcon("SettingsWindow.Logout", bundles.buttons_main.frames.save_progress_icon),
            onClicked: function () {
                connector.social.logout();
                this.closeWindow();
            }.bind(this)
        });
    },

    createFollowUs: function () {
        if (connector.platform.oneOf(
            connector.MOBAGE, 
            connector.SP_MOBAGE, 
            connector.GDCOM, 
            connector.COOLMATHGAMES, 
            connector.PLINGA, 
            connector.AMAZON,
            connector.DIGITAL,
            connector.DIGITALWEB,
            connector.RUSTORE,
            connector.YANDEX,
            connector.MICROSOFT,
            connector.MYGAMES,
            connector.TON,
            connector.WORTAL,
            connector.WECHAT,
            connector.YOUTUBE,
            connector.GAMESNACK
        )) {
            return;
        }

        var styles = cleverapps.styles.SettingsWindow;
        var isVertical = cleverapps.resolution.isVertical();

        var text = cleverapps.UI.generateOnlyText("SettingsWindow.FollowUs", cleverapps.styles.FONTS.SETTINGS_WINDOW_FOLLOW_US_TEXT);
        text.setOpacity(128);

        var icon = bundles.group_icons.frames[connector.info.source + "_icon"] || bundles.group_icons.frames.facebook_icon;
        var sprite = new cc.Sprite(icon);

        cleverapps.UI.onClick(sprite, function () {
            connector.social.joinCommunityCallback();
        }, { hover: true });

        var layout = new cleverapps.Layout([text, isVertical && this.createLine(), sprite].filter(Boolean), {
            direction: isVertical ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
            margin: styles.followUs.margin
        });

        return cleverapps.UI.wrapWithPadding(layout, {
            x: Math.max(0, (styles.button.width - layout.width) / 2)
        });
    },

    createVersion: function () {
        var text = cleverapps.UI.generateOnlyText("v" + cleverapps.config.version, cleverapps.styles.FONTS.SETTINGS_WINDOW_VERSION_TEXT);
        text.fitTo(cleverapps.styles.SettingsWindow.button.width);
        text.setOpacity(128);
        return text;
    },

    addCrossPromoButton: function () {
        var styles = cleverapps.styles.SettingsWindow.button;

        return new cleverapps.UI.Button({
            content: this.createTextWithIcon("CrossPromo.title", bundles.buttons_main.frames.more_games_icon),
            onClicked: function () {
                cleverapps.CrossPromo.OpenCrossPromoWindow();
                this.close();
            }.bind(this),
            width: styles.width,
            height: styles.height,
            mark: cleverapps.crossPromo && cleverapps.crossPromo.hasMark()
        });
    },

    createTextWithIcon: function (text, icon) {
        var styles = cleverapps.styles.SettingsWindow.button;

        icon = new cc.Sprite(icon);

        var iconWrapper = cleverapps.UI.wrapWithPadding(icon);
        iconWrapper.width = styles.icon.width;
        icon.x = iconWrapper.width / 2;

        text = cleverapps.UI.generateOnlyText(text, cleverapps.styles.FONTS.BUTTON_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);

        var textWrapper = cleverapps.UI.wrapWithPadding(text);
        textWrapper.width = styles.text.width;
        text.x = textWrapper.width / 2;
        text.fitTo(textWrapper.width);

        var layout = new cleverapps.Layout([iconWrapper, textWrapper], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin,
            padding: styles.padding
        });

        layout.text = text;
        layout.icon = icon;

        return layout;
    },

    adjustButtons: function () {
        var fontSize = cleverapps.styles.FONTS.BUTTON_TEXT.size;
        var buttons = this.buttonsLayout.children;
        var i;

        for (i = 0; i < buttons.length; i++) {
            fontSize = Math.min(fontSize, buttons[i].content.text.getFontSize());
        }

        for (i = 0; i < buttons.length; i++) {
            buttons[i].content.text.setFontSize(fontSize);
        }
    },

    closeWindow: function () {
        if (!this.closed) {
            this.close();
        }
    },

    listBundles: function () {
        return ["settings_window"];
    }
});

SettingsWindow.IsExitButtonAvailable = function () {
    return [cc.sys.OS_ANDROID].indexOf(connector.info.os) !== -1 && connector.info.isNative && connector.platform.hasCloseApplication();
};

cleverapps.styles.COLORS = cleverapps.overrideColors(cleverapps.styles.COLORS, {
    SETTINGS_WINDOW_TEXT_COLOR: new cc.Color(161, 80, 37, 255)
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    SETTINGS_WINDOW_NAME_TEXT: {
        name: "nostroke",
        size: 40,
        color: cleverapps.styles.COLORS.SETTINGS_WINDOW_TEXT_COLOR
    },

    SETTINGS_WINDOW_ID_TEXT: {
        name: "nostroke",
        size: 30,
        color: cleverapps.styles.COLORS.SETTINGS_WINDOW_TEXT_COLOR
    },

    SETTINGS_WINDOW_FOLLOW_US_TEXT: {
        name: "nostroke",
        size: 33,
        color: cleverapps.styles.COLORS.SETTINGS_WINDOW_TEXT_COLOR
    },

    SETTINGS_WINDOW_VERSION_TEXT: {
        name: "nostroke",
        size: 25,
        color: cleverapps.styles.COLORS.SETTINGS_WINDOW_TEXT_COLOR
    }
});

cleverapps.overrideTemplates(cleverapps.styles.UI.Button.Images, {
    settings_control_button: {
        button_png: bundles.buttons.frames.button_png,
        button_on_png: bundles.buttons.frames.button_on_png,
        button_off_png: bundles.buttons.frames.button_disabled_png,
        SKIP_RESOLUTION: {
            padding: {
                left: 0.05,
                right: 0.05,
                top: 0.05,
                bottom: 0.05
            }
        }
    },

    settings_control_red_button: {
        button_png: bundles.buttons.frames.red_button,
        button_on_png: bundles.buttons.frames.red_button_on,
        button_off_png: bundles.buttons.frames.button_disabled_png,
        SKIP_RESOLUTION: {
            padding: {
                left: 0.05,
                right: 0.05,
                top: 0.05,
                bottom: 0.05
            }
        }
    }
});

cleverapps.styles.SettingsWindow = {
    margin: 20,
    padding: {},

    user: {
        size: [{
            width: 650,
            height: 175
        }, {
            width: 1000,
            height: 175
        }, {
            width: 1000,
            height: 175
        }],

        padding: {
            y: 10,
            left: 20
        }
    },

    name: {
        position: [{
            x: { align: "left", dx: 174 },
            y: { align: "center", dy: 35 }
        }, {
            x: { align: "left", dx: 220 },
            y: { align: "center", dy: 35 }
        }, {
            x: { align: "left", dx: 220 },
            y: { align: "center", dy: 35 }
        }],

        size: [{ width: 360 }, { width: 500 }, { width: 500 }]
    },

    id: {
        position: [{
            x: { align: "left", dx: 174 },
            y: { align: "center", dy: -30 }
        }, {
            x: { align: "left", dx: 220 },
            y: { align: "center", dy: -30 }
        }, {
            x: { align: "left", dx: 220 },
            y: { align: "center", dy: -30 }
        }],

        size: [{ width: 360 }, { width: 500 }, { width: 500 }],

        margin: 10
    },

    avatar: {
        position: {
            x: { align: "left", dx: -30 },
            y: { align: "center" }
        },

        padding: {
            x: 18,
            top: 18,
            bottom: 18
        }
    },

    invite: {
        position: [{
            x: { align: "right", dx: -15 },
            y: { align: "top", dy: -15 }
        }, {
            x: { align: "right", dx: -30 },
            y: { align: "top", dy: -15 }
        }, {
            x: { align: "right", dx: -30 },
            y: { align: "top", dy: -15 }
        }],

        width: 100,
        height: 80
    },

    grid: {
        margin: {
            x: 50,
            y: 18
        }
    },

    button: {
        width: 560,
        height: 125,

        icon: {
            width: 70
        },

        margin: 10,

        text: {
            width: 390
        },

        padding: {
            right: 30
        }
    },

    controlButton: {
        margin: 10,

        width: 130,
        height: 110
    },

    cheapProduct: {
        x: { align: "right" },
        y: { align: "bottom" },
        width: 120,
        height: 120
    },

    line: {
        size: [{
            width: 570,
            height: 5
        }, {
            width: 1175,
            height: 5
        }, {
            width: 1175,
            height: 5
        }],

        padding: [
            { y: 0 },
            { top: 10, bottom: 6 },
            { top: 10, bottom: 6 }
        ]
    },

    followUs: {
        margin: 20
    }
};
